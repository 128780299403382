var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "37",
        height: "37",
        viewBox: "0 0 37 37",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M18.5002 20.0416C19.3516 20.0416 20.0418 19.3514 20.0418 18.4999C20.0418 17.6485 19.3516 16.9583 18.5002 16.9583C17.6487 16.9583 16.9585 17.6485 16.9585 18.4999C16.9585 19.3514 17.6487 20.0416 18.5002 20.0416Z",
          fill: "#606266",
          stroke: "#606266",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.2917 20.0416C30.1431 20.0416 30.8333 19.3514 30.8333 18.4999C30.8333 17.6485 30.1431 16.9583 29.2917 16.9583C28.4402 16.9583 27.75 17.6485 27.75 18.4999C27.75 19.3514 28.4402 20.0416 29.2917 20.0416Z",
          fill: "#606266",
          stroke: "#606266",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7.70817 20.0416C8.55961 20.0416 9.24984 19.3514 9.24984 18.4999C9.24984 17.6485 8.55961 16.9583 7.70817 16.9583C6.85673 16.9583 6.1665 17.6485 6.1665 18.4999C6.1665 19.3514 6.85673 20.0416 7.70817 20.0416Z",
          fill: "#606266",
          stroke: "#606266",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }